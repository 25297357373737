:root{
    --background: #29d;
    --border-right: rgba(127, 127, 127, .2);
    --border: #fff;
}

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace .pace-activity {
    display: block;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 0;
    width: 150px;
    height: 150px;
    background: var(--background);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transform: translateX(100%) translateY(-100%) rotate(45deg);
    transform: translateX(100%) translateY(-100%) rotate(45deg);
    pointer-events: none;
}

.pace.pace-active .pace-activity {
    -webkit-transform: translateX(50%) translateY(-50%) rotate(45deg);
    transform: translateX(50%) translateY(-50%) rotate(45deg);
}

.pace .pace-activity::before,
.pace .pace-activity::after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    bottom: 15px;
    left: 50%;
    display: block;
    border: 2px solid var(--border);
    border-radius: 50%;
    content: '';
}

.pace .pace-activity::before {
    margin-left: -20px;
    width: 40px;
    height: 40px;
    border-right-color: var(--border-right);
    border-left-color: var(--border-right);
    -webkit-animation: pace-theme-corner-indicator-spin 3s linear infinite;
    animation: pace-theme-corner-indicator-spin 3s linear infinite;
}

.pace .pace-activity::after {
    bottom: 25px;
    margin-left: -10px;
    width: 20px;
    height: 20px;
    border-top-color: var(--border-right);
    border-bottom-color: var(--border-right);
    -webkit-animation: pace-theme-corner-indicator-spin 1s linear infinite;
    animation: pace-theme-corner-indicator-spin 1s linear infinite;
}


@-webkit-keyframes pace-theme-corner-indicator-spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(359deg); }
}
@keyframes pace-theme-corner-indicator-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(359deg); }
}

@media screen and (max-width:900px) { .fc-toolbar.fc-header-toolbar  {font-size: 60%} }

@media screen and (max-width:900px) {
    .fc-toolbar-chunk {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .fc-col-header-cell-cushion {
        font-size: 12px;
    }
}